import React, { useEffect} from 'react';
import { Container, Row, Col, Form, Button, Spinner, Card } from "react-bootstrap";
import Aos from "aos";
import "aos/dist/aos.css";

const About = () => {
    useEffect(() => {
        Aos.init({ duration: 2000 });
    }, [])
    return (
        <div style={{width:"100%", overflow:"hidden"}}>
            <div data-aos="slide-up" className="spacer2 blackwhitelayer">
                <h1 data-aos="fade-up" className="about-start">ABOUT PLUNGE</h1>
                <h1 data-aos="fade-up" className="about-title">WHAT'S INSIDE THE CAN?</h1>
                <p data-aos="fade-up" className="about-p">A very good question! Short answer - there's a whole lotta stuff inside the can. Every Plunge can is packed with surprise and fresh adventure waiting to be opened. You'll never know where your next sip of Plunge might take you. Maybe to the serene beaches of Maldives. Maybe on a yatch cruise in Dubai. Or on a bar crawl in Vegas! You'll never know! </p>
                <Row>
                    <Col lg="7" className="about-img-col">
                        <img data-aos="fade-up" className="about-img" src="https://trendbucket.s3.ap-south-1.amazonaws.com/beach.png"/>
                    </Col>
                    <Col lg="5" className="about-text-col">
                        <h1 data-aos="slide-left" className="about-title-right">Not just for <span style={{color:"#FF033E"}}>Drinkers.</span> <br/>For <span style={{color:"#FF033E"}}>Dreamers.</span></h1>
                    </Col>
                </Row>
            </div>
            <div data-aos="slide-up" className="spacer2 whiteblacklayer">
                <Row>
                    <Col lg="5" className="about-text-col">
                        <h1 data-aos="slide-right" className="about-title-left">BRING THE <span style={{color:"#FEBE10"}}>BAR</span> BACK <span style={{color:"#FEBE10"}}>HOME</span></h1>
                        <p data-aos="slide-right" className="about-p-left">Get your favorite cocktails at the convenience of your home. Now you don't have to pay hefty amounts to enjoy a good, tasty cocktail! <br/><br/>Each Plunge cocktail is crafted by professional mixologists using selected ingredients to give you a consistent, fresh taste every time.<br/><br/><span style={{color:"#FEBE10"}}>Same freshness. Premium taste. Affordable prices.</span></p>
                    </Col>
                    <Col lg="7" className="about-img-col">
                        <img data-aos="fade-up" className="about-img" src="https://trendbucket.s3.ap-south-1.amazonaws.com/bartender.png"/>
                    </Col>
                </Row>
            </div>
            <div data-aos="slide-up" className="spacer2 blackwhitelayer">
                <Row>
                    <Col lg="7" className="about-img-col">
                        <img data-aos="slide-up" className="about-last-img" src="https://trendbucket.s3.ap-south-1.amazonaws.com/diveplunge.png"/>
                    </Col>
                    <Col lg="5" className="about-text-col">
                        <h1 data-aos="slide-up" className="about-last-h1"><span className="about-span">DIVE</span> INTO THE <span style={{color:"#FF4500"}}>SWEET*</span> PLUNGE<span className="about-span"> LIFE</span></h1>
                        <h1 data-aos="slide-up" className="about-last-h2">*MINUS THE SUGAR</h1>
                        <p data-aos="slide-up" className="about-last-p">Let's face it. Life is much healthier and sweeter with less sugar in your drinks. Well, Plunge is zero sugar. <br/><br/>The end result?<br/> <span style={{color:"#FF4500"}}>Healthier you, happier days and sweeter memories!</span></p>
                    </Col>
                </Row>
            </div>
        </div>
    );
};

export default About;