import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './css/main.css';
import './css/events.css';
import './css/flavors.css';
import './css/about.css';
import './css/story.css';
import './css/contact.css';
import './css/home.css';
import "animate.css/animate.min.css";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
