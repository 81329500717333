import React, { useEffect } from "react";
import { Container, Row, Col, Form, Button, Spinner, Card } from "react-bootstrap";
import { Link } from 'react-router-dom'
import AnimatedText from 'react-animated-text-content';
import Jump from 'react-reveal/Jump';
import Tada from 'react-reveal/Tada';
import Fade from 'react-reveal/Fade';
import Slide from 'react-reveal/Slide';
import Bounce from 'react-reveal/Bounce';
import Aos from "aos";
import "aos/dist/aos.css";


const Home = () => {
    useEffect(() => {
        Aos.init({ duration: 2000 });
    }, [])
    return (
        <div style={{width:"100%", overflow:"hidden"}}>
            <Row xs={1} sm={1} lg={2} className='home-row'>
                <Col className="home-col">
                    <div className="home-div">
                        <p className="homefont">TAKE THE <p style={{color:"maroon"}}>PLUNGE</p></p>
                        <img src="https://trendbucket.s3.ap-south-1.amazonaws.com/flamingoplunge.png" className="home-flamingo"/>
                        <p className="homesubsubfont">DIP. DIVE. <br/> <p style={{color:"maroon"}}>DISCOVER.</p></p>
                    </div>
                    <Button data-aos="fade-right" size="lg" className="home-button" style={{backgroundColor:"black"}}><Link to="/story" style={{textDecoration:"none", color:"white"}} onClick={() => window.scrollTo({top: 0,behavior: 'smooth'})}>Dive Inside</Link></Button>
                </Col>
                <Col className="home-col">
                    <div className="home-right-div">
                    <Bounce top>
                        <img className='home-img' src="https://trendbucket.s3.ap-south-1.amazonaws.com/cans.png"/>
                    </Bounce>
                    </div>
                </Col>
            </Row>
            <div className="spacer1 layer8 home-div-2">
                <h1 data-aos="fade-up" className='home-title'>8% ABV, ZERO SUGAR.</h1>
                <h1 data-aos="fade-up" style={{fontFamily:"'Rubik Dirt', cursive", color:"black"}}>REFRESHINGLY REAL FLAVORS</h1>
                <Button style={{backgroundColor:"black", color:"white", fontFamily: "'Rowdies', cursive", marginTop:"1rem", marginBottom:"1rem"}}><Link to="/cocktails" style={{textDecoration:"none", color:"white"}} onClick={() => window.scrollTo({top: 0,behavior: 'smooth'})}>Explore Cocktail Menu</Link></Button>
                {/* <h1 style={{fontFamily:"'Rubik Dirt', cursive", textAlign:"center", width:"50%", margin:"auto", marginTop:"12rem"}}>PLUNGE INTO THE WORLD OF PREMIUM TASTE WITHOUT WORRYING ABOUT UNNECESSARY SUGAR & CALORIES.</h1> */}
                <Row xs={1} sm={3} lg={5} style={{width:"80%", margin:"auto", marginTop:"2rem", textAlign:"center"}}>
                    <Col>
                        <Card data-aos="slide-up" style={{margin:"auto", border:"none", backgroundColor:"transparent", width:"100%"}}>
                            <Card.Img data-aos="slide-up" variant="top" src="https://trendbucket.s3.ap-south-1.amazonaws.com/guavacan.png" style={{width:"100%", margin:"auto"}}/>
                            <Card.Body>
                                <Card.Title style={{fontFamily:"'Rubik Dirt', cursive"}}>GUAVA <br/> MULE</Card.Title>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col>
                        <Card data-aos="slide-up" style={{ margin:"auto", border:"none", backgroundColor:"transparent", width:"100%" }}>
                            <Card.Img data-aos="slide-up" variant="top" src="https://trendbucket.s3.ap-south-1.amazonaws.com/berrycan.png" style={{width:"100%", margin:"auto"}}/>
                            <Card.Body>
                                <Card.Title style={{fontFamily:"'Rubik Dirt', cursive"}}>BERRY <br/>BOMB</Card.Title>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col>
                        <Card data-aos="slide-up" style={{ margin:"auto", border:"none", backgroundColor:"transparent", width:"100%" }}>
                            <Card.Img data-aos="slide-up" variant="top" src="https://trendbucket.s3.ap-south-1.amazonaws.com/cosmocan.png" style={{width:"100%", margin:"auto"}}/>
                            <Card.Body>
                                <Card.Title style={{fontFamily:"'Rubik Dirt', cursive"}}>WATERMELON <br/> MINT COSMO</Card.Title>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col>
                        <Card data-aos="slide-up" style={{  margin:"auto", border:"none", backgroundColor:"transparent", width:"100%" }}>
                            <Card.Img data-aos="slide-up" variant="top" src="https://trendbucket.s3.ap-south-1.amazonaws.com/pinacan.png" style={{width:"100%", margin:"auto"}}/>
                            <Card.Body>
                                <Card.Title style={{fontFamily:"'Rubik Dirt', cursive"}}>TROPICAL <br/> PINACOLADA</Card.Title>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col>
                        <Card data-aos="slide-up" style={{ margin:"auto", border:"none", backgroundColor:"transparent", width:"100%" }}>
                            <Card.Img data-aos="slide-up" variant="top" src="https://trendbucket.s3.ap-south-1.amazonaws.com/orangecan.png" style={{width:"100%", margin:"auto"}}/>
                            <Card.Body>
                                <Card.Title style={{fontFamily:"'Rubik Dirt', cursive"}}>ORANGE PASSION <br/> SCREWDRIVER</Card.Title>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <img data-aos="" src="https://trendbucket.s3.ap-south-1.amazonaws.com/djball.png" className="djball"/>
                <h1 data-aos="zoom-in-up" className="home-highlight"><mark style={{backgroundColor:"black", color:"#FFC72C"}}>PLUNGE</mark> into the the world of quality and<br/><mark style={{backgroundColor:"black", color:"#FFC72C"}}>PREMIUM&nbsp; &nbsp;TASTE</mark> without worrying about <mark style={{backgroundColor:"black", color:"#FFC72C"}}>UNNECESSARY SUGAR AND CALORIES.</mark></h1>
            </div>
            <div data-aos="slide-up" style={{marginTop:"2rem", backgroundColor:"black",}}>
                {/* <h1 style={{fontFamily:"'Rubik Dirt', cursive", fontSize:"5rem", textAlign:"center", marginTop:"0rem", backgroundColor:"black", color:"white", padding:"1rem"}}>Why <span style={{color:"#FFC72C"}}>Plunge</span>?</h1> */}
                <Row xs={1} sm={2} lg={4} style={{margin:"auto", marginTop:"5rem", width:"80%", color:"white"}}>
                    <Col>
                        <Card style={{ width: '18rem', margin:"auto", border:"none", backgroundColor:"transparent" }}>
                            <Card.Img variant="top" src="https://trendbucket.s3.ap-south-1.amazonaws.com/sugarfree.png" style={{width:"80%", margin:"auto"}} />
                            <Card.Body>
                                <Card.Title style={{fontFamily:"'Rubik Dirt', cursive"}}>ZERO SUGAR</Card.Title>
                                <Card.Text style={{fontFamily:"'Rowdies', cursive"}}>
                                Plunge contains 0g sugar. Instead, we use natural Stevia to sweeten the drinks providing you healthier drinks with the perfect taste.
                                </Card.Text>
                                {/* <Button variant="primary">Go somewhere</Button> */}
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col>
                        <Card style={{ width: '18rem', margin:"auto", border:"none", backgroundColor:"transparent" }}>
                            <Card.Img variant="top" src="https://trendbucket.s3.ap-south-1.amazonaws.com/lowcal.png" style={{width:"80%", margin:"auto"}} />
                            <Card.Body>
                                <Card.Title style={{fontFamily:"'Rubik Dirt', cursive"}}>LOW CAL, MORE BUZZ</Card.Title>
                                <Card.Text style={{fontFamily:"'Rowdies', cursive"}}>
                                At 8% alcohol, Plunge gives you wayyy more buzz in wayyyy less calories than Beer or traditional bar cocktails.
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col>
                        <Card style={{ width: '18rem', margin:"auto", border:"none", backgroundColor:"transparent" }}>
                            <Card.Img variant="top" src="https://trendbucket.s3.ap-south-1.amazonaws.com/flavor.png" style={{width:"80%", margin:"auto"}}/>
                            <Card.Body>
                                <Card.Title style={{fontFamily:"'Rubik Dirt', cursive"}}>MORE FLAVOR</Card.Title>
                                <Card.Text style={{fontFamily:"'Rowdies', cursive"}}>
                                Plunge comes in 5 unique, complicated flavors combining the best ingredients that taste amazing together, giving you the perfect hassle-free drinks.
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col>
                        <Card style={{ width: '18rem', margin:"auto", border:"none", backgroundColor:"transparent" }}>
                            <Card.Img variant="top" src="https://trendbucket.s3.ap-south-1.amazonaws.com/can.png" style={{width:"80%", margin:"auto"}}/>
                            <Card.Body>
                                <Card.Title style={{fontFamily:"'Rubik Dirt', cursive"}}>EASY & CONVENIENT</Card.Title>
                                <Card.Text style={{fontFamily:"'Rowdies', cursive"}}>
                                Our cans give you the convenience and the freedom to carry your cocktails anywhere you go with ease - while traveling, at parties, on the beach or anywhere else you can think of.
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </div>
            <div data-aos="fade-up" className="spacer2 layer" style={{marginTop:"5rem", marginBottom:"0"}}>
                <h1 data-aos="fade-up" className="home-media">PLUNGE IN MEDIA</h1>
                <h1 data-aos="fade-up" className="home-reviews">Our reviews are sparkling as well...</h1>
                <Row xs={1} sm={2} lg={4} style={{margin:"auto", marginTop:"5rem", width:"80%"}}>
                    <h1 style={{margin:"auto", marginTop:"5rem", width:"80%", fontFamily:"'Rubik Dirt', cursive", textAlign:"center", color:"white"}}>EDITORS IN THE COUNTRY ARE BUSY TASTING OUR DRINKS BEFORE WRITING ABOUT THEM.<br/>PUBLISHING SOON...</h1>
                    {/* <Col>
                        <Card data-aos="fade-right" style={{ width: '18rem', margin:"auto", border:"none", backgroundColor:"transparent" }}>
                            <Card.Img variant="top" src="https://trendbucket.s3.ap-south-1.amazonaws.com/drinks.png" style={{width:"100%", margin:"auto"}} />
                            <Card.Body>
                                <Card.Title style={{fontFamily:"'Rubik Dirt', cursive"}}>- COCKTAIL MAGAZINE</Card.Title>
                                <Card.Text style={{fontFamily:"'Rowdies', cursive"}}>
                                Some of the best drinks we have tasted in a long while, providing the most premium cocktails combined with the convenience of a can.
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col>
                        <Card data-aos="fade-right" style={{ width: '18rem', margin:"auto", border:"none", backgroundColor:"transparent" }}>
                            <Card.Img variant="top" src="https://trendbucket.s3.ap-south-1.amazonaws.com/cocktails.png" style={{width:"100%", margin:"auto"}} />
                            <Card.Body>
                                <Card.Title style={{fontFamily:"'Rubik Dirt', cursive"}}>- THE BARTENDER</Card.Title>
                                <Card.Text style={{fontFamily:"'Rowdies', cursive"}}>
                                The Pinacolada and Jagerbomb flavors are amazing! Nothing like this in the Indian market.
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col> */}
                    {/* <Col>
                        <Card data-aos="fade-left" style={{ width: '18rem', margin:"auto", border:"none", backgroundColor:"transparent" }}>
                            <Card.Img variant="top" src="https://trendbucket.s3.ap-south-1.amazonaws.com/flavor.png" style={{width:"80%", margin:"auto"}}/>
                            <Card.Body>
                                <Card.Title style={{fontFamily:"'Rubik Dirt', cursive"}}>MORE FLAVOR</Card.Title>
                                <Card.Text style={{fontFamily:"'Rowdies', cursive"}}>
                                Some quick example text to build on the card title and make up the
                                bulk of the card's content.
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col> */}
                    {/* <Col>
                        <Card data-aos="fade-left" style={{ width: '18rem', margin:"auto", border:"none", backgroundColor:"transparent" }}>
                            <Card.Img variant="top" src="https://trendbucket.s3.ap-south-1.amazonaws.com/can.png" style={{width:"80%", margin:"auto"}}/>
                            <Card.Body>
                                <Card.Title style={{fontFamily:"'Rubik Dirt', cursive"}}>EASY & CONVENIENT</Card.Title>
                                <Card.Text style={{fontFamily:"'Rowdies', cursive"}}>
                                Some quick example text to build on the card title and make up the
                                bulk of the card's content.
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col> */}
                </Row>
                <h1 data-aos="slide-up" className="home-party">LET THE PARTY BEGIN!</h1>
                <h1 data-aos="slide-up" className="home-party-sub"><mark style={{backgroundColor:"black", color:"white"}}>&nbsp;Every hour is HAPPY HOUR now.</mark> <br/><mark style={{backgroundColor:"black", color:"#0CAFFF"}}>Put your best foot forward and &nbsp; <br/>&nbsp; recline backwards.</mark></h1>
            </div>
            <div className="spacer2 whiteblacklayer" style={{marginTop:"0"}}>
                <Row xs={1} sm={1} lg={2}>
                    <Col lg="5" className="home-col-left">
                        <h1 data-aos="fade-right" className="home-col-title">EVERYONE'S WELCOME TO THIS PARTY</h1>
                        <h1 data-aos="fade-right" className="home-col-sub">JUST REMEMBER TO KEEP THE DRINKS COLD AND THE VIBES HIGH...</h1>
                        <Button style={{backgroundColor:"black", fontFamily: "'Rowdies', cursive", marginTop:"1rem"}}>About Plunge</Button>
                    </Col>
                    <Col lg="7" style={{textAlign:"center", margin:"5rem 0 0 0"}}>
                        <img data-aos="fade-left" className="home-last-img" src="https://trendbucket.s3.ap-south-1.amazonaws.com/party1.png"/>
                    </Col>
                </Row>
                
            </div>
        </div>
    );
};

export default Home;