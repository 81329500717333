import React from 'react';

const Terms = () => {
    return (
        <div style={{width:"60%", margin:"auto", fontFamily:"League Spartan"}}>
            <h4>Terms of Use Agreement</h4>
            <p>
            Welcome to www.plungecocktails.com, the website and online service of Topdog Beverages Pvt. Ltd. (“Topdog”, "Plunge", "our", “we”, or “us”). 
            This page explains the terms by which you may use our online services, website, and software provided on or in connection with the service (collectively the “Service”). 
            By accessing or using the Service, you signify that you have read, understood, and agree to be bound by this Terms of Service Agreement (“Agreement”) 
            and to the collection and use of your information as set forth in the Plunge Privacy Policy (https://www.plungecocktails.com/privacy), 
            whether or not you are a registered user of our Service. This Agreement applies to all visitors, users, and others who access the Service (“Users”).
            </p>
            <h5>Last updated on Feb 02, 2023.</h5>
            <p>
            PLEASE READ THIS AGREEMENT CAREFULLY TO ENSURE THAT YOU UNDERSTAND EACH PROVISION. 
            THIS AGREEMENT CONTAINS A MANDATORY ARBITRATION OF DISPUTES PROVISION THAT REQUIRES THE USE OF ARBITRATION ON AN INDIVIDUAL BASIS TO RESOLVE DISPUTES, 
            RATHER THAN JURY TRIALS OR CLASS ACTIONS.
            </p>
            <ol>
                <li>
                    <h5>Use of Our Service</h5>
                        <ul>
                            <li>
                                <h6>Eligibility</h6>
                                <p>
                                    You may use the Service only if you can form a binding contract with TheTrendNote, and only in compliance with this Agreement and all 
                                    applicable local, state, national, and international laws, rules and regulations. Any use or access to the Service by anyone under 13 is 
                                    strictly prohibited and in violation of this Agreement. The Service is not available to any Users previously removed from the Service by 
                                    TheTrendNote.
                                </p>
                            </li>
                            <li>
                                <h6>TheTrendNote Service</h6>
                                <p>
                                    Subject to the terms and conditions of this Agreement, you are hereby granted a non-exclusive, limited, non-transferable, freely revocable 
                                    license to use the Service as permitted by the features of the Service. TheTrendNote reserves all rights not expressly granted herein in the 
                                    Service and the TheTrendNote Content (as defined below). TheTrendNote may terminate this license at any time for any reason or no reason.
                                </p>
                            </li>
                            <li>
                                <h6>Service Rules</h6>
                                <p>
                                    You agree not to engage in any of the following prohibited activities: (i) copying, distributing, or disclosing any part of the Service in any 
                                    medium, including without limitation by any automated or non-automated “scraping”; (ii) using any automated system, including without limitation 
                                    “robots,” “spiders,” “offline readers,” etc., to access the Service in a manner that sends more request messages to the TheTrendNote servers than 
                                    a human can reasonably produce in the same period of time by using a conventional on-line web browser (iii) transmitting spam, chain letters, or 
                                    other unsolicited email; (iv) attempting to interfere with, compromise the system integrity or security or decipher any transmissions to or from 
                                    the servers running the Service; (v) taking any action that imposes, or may impose at our sole discretion an unreasonable or disproportionately 
                                    large load on our infrastructure; (vi) uploading invalid data, viruses, worms, or other software agents through the Service; (vii) collecting or 
                                    harvesting any personally identifiable information, including account names, from the Service; (viii) using the Service for any commercial 
                                    solicitation purposes; (ix) impersonating another person or otherwise misrepresenting your affiliation with a person or entity, conducting fraud, 
                                    hiding or attempting to hide your identity; (x) interfering with the proper working of the Service; (xi) accessing any content on the Service 
                                    through any technology or means other than those provided or authorized by the Service; or (xii) bypassing the measures we may use to prevent or 
                                    restrict access to the Service, including without limitation features that prevent or restrict use or copying of any content or enforce limitations 
                                    on use of the Service or the content therein; or (xiii) monetizing TheTrendNote content through advertising, subscriptions or other means. 
                                    We may, without prior notice, change the Service; stop providing the Service or features of the Service, to you or to users generally; or 
                                    create usage limits for the Service. We may permanently or temporarily terminate or suspend your access to the Service without notice and 
                                    liability for any reason, including if in our sole determination you violate any provision of this Agreement, or for no reason. 
                                    Upon termination for any reason or no reason, you continue to be bound by this Agreement. You are solely responsible for your interactions with 
                                    other TheTrendNote Users. We reserve the right, but have no obligation, to monitor disputes between you and other Users. TheTrendNote shall have 
                                    no liability for your interactions with other Users, or for any User’s action or inaction.
                                </p>
                            </li>
                        </ul>
                        <p>
                            The Trend Note reserves the right, at any time, to modify, suspend, or discontinue the Site (in whole or in part) at its sole discretion 
                            with or without notice to you. You agree that The Trend Note will not be liable to you or to any third party for any modification, suspension, 
                            or discontinuation of the Site or any part thereof.
                        </p>
                        <p>
                            The Trend Note further reserves the right, at any time, to revise these Terms or to impose new terms and conditions with respect to access to or use 
                            of the Site, the Content, or any other matter, in its sole discretion. Any modification to the Terms shall become effective when posted. 
                            ANY ACCESS TO OR USE OF THIS SITE OR ANY CONTENT BY YOU AFTER THE POSTING OF THE REVISED TERMS SHALL CONSTITUTE YOUR AGREEMENT TO SUCH REVISED TERMS. 
                            No modification to these Terms shall be valid or enforceable against The Trend Note unless expressly agreed to by The Trend Note in a writing signed 
                            by a duly authorized officer of The Trend Note.
                        </p>
                </li>
                <li>
                    <h5>Our Proprietary Rights</h5>
                    <p>
                        The Service and all materials therein or transferred thereby, including, without limitation, software, images, text, graphics, illustrations, logos, 
                        patents, trademarks, service marks, copyrights and photographs (the “TheTrendNote Content”), and all Intellectual Property Rights related thereto, 
                        are the exclusive property of TheTrendNote and its licensors. Except as explicitly provided herein, nothing in this Agreement shall be deemed to 
                        create a license in or under any such Intellectual Property Rights, and you agree not to sell, license, rent, modify, distribute, copy, reproduce, transmit, 
                        publicly display, publicly perform, publish, adapt, edit or create derivative works from any TheTrendNote Content. Use of the TheTrendNote Content for any 
                        purpose not expressly permitted by this Agreement is strictly prohibited. You may choose to or we may invite you to submit comments or ideas about the 
                        Service, including without limitation about how to improve the Service or our products (“Ideas”). By submitting any Idea, you agree that your disclosure 
                        is gratuitous, unsolicited and without restriction and will not place TheTrendNote under any fiduciary or other obligation, and that we are free to use the 
                        Idea without any additional compensation to you, and/or to disclose the Idea on a non-confidential basis or otherwise to anyone. You further acknowledge 
                        that, by acceptance of your submission, TheTrendNote does not waive any rights to use similar or related ideas previously known to TheTrendNote, or 
                        developed by its employees, or obtained from sources other than you.
                    </p>
                </li>
                <li>
                    <h5>Paid Services</h5>
                    <ul>
                        <li>
                            <h6>Billing Policies</h6>
                            <p>
                                Certain aspects of the Service may be provided for a fee or other charge. TheTrendNote may add new services for additional fees and charges, or 
                                amend fees and charges for existing services, at any time in its sole discretion. Any change to our pricing or payment terms shall become effective 
                                in the billing cycle following notice of such change to you.
                            </p>
                        </li>
                        <li>
                            <h6>No Refunds</h6>
                            <p>
                                You may cancel your TheTrendNote subscription at any time; however, there are no refunds for cancellation; if you cancel before the end of your billing 
                                cycle you have access to your subscription for the remainder of the billing cycle. In the event that TheTrendNote suspends or terminates your 
                                subscription or this Agreement for your breach of this Agreement, you understand and agree that you shall receive no refund or exchange for any unused 
                                time on a subscription, any license or subscription fees for any portion of the Service, any content or data associated with your subscription, 
                                or for anything else.
                            </p>
                        </li>
                        <li>
                            <h6>Payment Information; Taxes</h6>
                            <p>
                                All information that you provide in connection with a purchase or transaction or other monetary transaction interaction with the Service must be accurate, 
                                complete, and current. You agree to pay all charges incurred by users of your credit card, debit card, or other payment method used in connection 
                                with a purchase or transaction or other monetary transaction interaction with the Service at the prices in effect when such charges are incurred. 
                                You will pay any applicable taxes, if any, relating to any such purchases, transactions or other monetary transaction interactions.
                            </p>
                        </li>
                    </ul>
                </li>
                <li>
                    <h5>No Professional Advice</h5>
                    <p>
                        If the Service provides professional information (for example, financial, legal, economic), such information is for informational purposes only and 
                        should not be construed as professional advice. No action should be taken based upon any information contained in the Service. You should seek 
                        independent professional advice from a person who is licensed and/or qualified in the applicable area
                    </p>
                </li>
                <li>
                    <h5>Privacy</h5>
                    <p>
                        We care about the privacy of our Users. You understand that by using the Services you consent to the collection, use and disclosure of your personally 
                        identifiable information and aggregate data as set forth in our Privacy Policy (https://www.thetrendnote.com/privacy), and to have your personally 
                        identifiable information collected, used, transferred to and processed in India.
                    </p>
                </li>
                <li>
                    <h5>Security</h5>
                    <p>
                        TheTrendNote cares about the integrity and security of your personal information. However, we cannot guarantee that unauthorized third parties will 
                        never be able to defeat our security measures or use your personal information for improper purposes. You acknowledge that you provide your personal 
                        information at your own risk.
                    </p>
                </li>
                <li>
                    <h5>Third-Party Links</h5>
                    <p>
                        The Service may contain links to third-party websites, services or other events or activities that are not owned or controlled by TheTrendNote. 
                        TheTrendNote does not endorse or assume any responsibility for any such third-party sites, information, materials, products, or services. 
                        If you access a third party website from the Service, you do so at your own risk, and you understand that this Agreement and TheTrendNote’s Privacy Policy 
                        do not apply to your use of such sites. You expressly relieve TheTrendNote from any and all liability arising from your use of any third-party website, 
                        service, or content.
                    </p>
                </li>
                <li>
                    <h5>Mark Usage</h5>
                    <p>
                        Use of Customer Marks. TheTrendNote may use Customer’s name, logos, or other trademarks in marketing materials, websites, or other public facing 
                        communications.
                    </p>
                </li>
                <li>
                    <h5>Indemnity</h5>
                    <p>
                        You agree to defend, indemnify and hold harmless TheTrendNote and its subsidiaries, agents, licensors, managers, and other affiliated companies, 
                        and their employees, contractors, agents, officers and directors, from and against any and all claims, damages, obligations, losses, liabilities, 
                        costs or debt, and expenses (including but not limited to attorney’s fees) arising out of or related to: (i) your use of and access to the Service, 
                        including any data or content transmitted or received by you; (ii) your violation of any term of this Agreement, including without limitation your breach 
                        of any of the representations and warranties above; (iii) your violation of any third-party right, including without limitation any right of privacy or 
                        Intellectual Property Rights; (iv) your violation of any applicable law, rule or regulation; (v) any other information or content that is submitted via 
                        your account including without limitation misleading, false or inaccurate information; (vi) negligent or willful misconduct; or (vii) any other party’s 
                        access and use of the Service with your unique username, password or other appropriate security code.
                    </p>
                </li>
                <li>
                    <h5>No Warranty</h5>
                    <p>
                        THE SERVICE IS PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS. USE OF THE SERVICE IS AT YOUR OWN RISK. TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, 
                        THE SERVICE IS PROVIDED WITHOUT WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, IMPLIED WARRANTIES OF MERCHANTABILITY, 
                        FITNESS FOR A PARTICULAR PURPOSE, OR NON-INFRINGEMENT. NO ADVICE OR INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED BY YOU FROM COMPANY OR THROUGH 
                        THE SERVICE WILL CREATE ANY WARRANTY NOT EXPRESSLY STATED HEREIN. WITHOUT LIMITING THE FOREGOING, COMPANY, ITS SUBSIDIARIES, ITS AFFILIATES, AND ITS 
                        LICENSORS DO NOT WARRANT THAT THE CONTENT IS ACCURATE, RELIABLE OR CORRECT; THAT THE SERVICE WILL MEET YOUR REQUIREMENTS; THAT THE SERVICE WILL BE 
                        AVAILABLE AT ANY PARTICULAR TIME OR LOCATION, UNINTERRUPTED OR SECURE; THAT ANY DEFECTS OR ERRORS WILL BE CORRECTED; OR THAT THE SERVICE IS FREE OF VIRUSES 
                        OR OTHER HARMFUL COMPONENTS. ANY CONTENT DOWNLOADED OR OTHERWISE OBTAINED THROUGH THE USE OF THE SERVICE IS DOWNLOADED AT YOUR OWN RISK AND YOU WILL BE 
                        SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR COMPUTER SYSTEM OR MOBILE DEVICE OR LOSS OF DATA THAT RESULTS FROM SUCH DOWNLOAD OR YOUR USE OF THE SERVICE. 
                        THIS AGREEMENT GIVES YOU SPECIFIC LEGAL RIGHTS, AND YOU MAY ALSO HAVE OTHER RIGHTS WHICH MIGHT VARY FROM STATE TO STATE. THE DISCLAIMERS AND EXCLUSIONS 
                        UNDER THIS AGREEMENT WILL NOT APPLY TO THE EXTENT PROHIBITED BY APPLICABLE LAW.
                    </p>
                </li>
                <li>
                    <h5>Limitation of Liability</h5>
                    <p>
                        TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT SHALL COMPANY, ITS AFFILIATES, AGENTS, DIRECTORS, EMPLOYEES, SUPPLIERS OR LICENSORS BE 
                        LIABLE FOR ANY DIRECT, INDIRECT, PUNITIVE, INCIDENTAL, SPECIAL, CONSEQUENTIAL OR EXEMPLARY DAMAGES, INCLUDING WITHOUT LIMITATION DAMAGES FOR LOSS OF 
                        PROFITS, GOODWILL, USE, DATA OR OTHER INTANGIBLE LOSSES, ARISING OUT OF OR RELATING TO THE USE OF, OR INABILITY TO USE, THIS SERVICE. UNDER NO CIRCUMSTANCES 
                        WILL COMPANY BE RESPONSIBLE FOR ANY DAMAGE, LOSS OR INJURY RESULTING FROM HACKING, TAMPERING OR OTHER UNAUTHORIZED ACCESS OR USE OF THE SERVICE OR YOUR 
                        ACCOUNT OR THE INFORMATION CONTAINED THEREIN. TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, COMPANY ASSUMES NO LIABILITY OR RESPONSIBILITY FOR ANY 
                        (I) ERRORS, MISTAKES, OR INACCURACIES OF CONTENT; (II) PERSONAL INJURY OR PROPERTY DAMAGE, OF ANY NATURE WHATSOEVER, RESULTING FROM YOUR ACCESS TO OR 
                        USE OF OUR SERVICE; (III) ANY UNAUTHORIZED ACCESS TO OR USE OF OUR SECURE SERVERS AND/OR ANY AND ALL PERSONAL INFORMATION STORED THEREIN; 
                        (IV) ANY INTERRUPTION OR CESSATION OF TRANSMISSION TO OR FROM THE SERVICE; (V) ANY BUGS, VIRUSES, TROJAN HORSES, OR THE LIKE THAT MAY BE TRANSMITTED TO 
                        OR THROUGH OUR SERVICE BY ANY THIRD PARTY; (VI) ANY ERRORS OR OMISSIONS IN ANY CONTENT OR FOR ANY LOSS OR DAMAGE INCURRED AS A RESULT OF THE USE OF ANY 
                        CONTENT POSTED, EMAILED, TRANSMITTED, OR OTHERWISE MADE AVAILABLE THROUGH THE SERVICE; AND/OR (VII) THE DEFAMATORY, OFFENSIVE, OR ILLEGAL CONDUCT OF ANY 
                        THIRD PARTY. IN NO EVENT SHALL COMPANY, ITS AFFILIATES, AGENTS, DIRECTORS, EMPLOYEES, SUPPLIERS, OR LICENSORS BE LIABLE TO YOU FOR ANY CLAIMS, PROCEEDINGS, 
                        LIABILITIES, OBLIGATIONS, DAMAGES, LOSSES OR COSTS IN AN AMOUNT EXCEEDING THE AMOUNT YOU PAID TO COMPANY HEREUNDER OR RS.10,000.00, WHICHEVER IS GREATER. 
                        THIS LIMITATION OF LIABILITY SECTION APPLIES WHETHER THE ALLEGED LIABILITY IS BASED ON CONTRACT, TORT, NEGLIGENCE, STRICT LIABILITY, OR ANY OTHER BASIS, 
                        EVEN IF COMPANY HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGE. THE FOREGOING LIMITATION OF LIABILITY SHALL APPLY TO THE FULLEST EXTENT PERMITTED BY 
                        LAW IN THE APPLICABLE JURISDICTION. THE EXCLUSIONS AND LIMITATIONS OF LIABILITY UNDER THIS AGREEMENT WILL NOT APPLY TO THE EXTENT PROHIBITED BY APPLICABLE 
                        LAW. The Service is controlled and operated from facilities in India. TheTrendNote makes no representations that the Service is appropriate or available 
                        for use in other locations. Those who access or use the Service from other jurisdictions do so at their own volition and are entirely responsible for 
                        compliance with all applicable Indian and local laws and regulations, including but not limited to export and import regulations. You may not use the Service 
                        if you are a resident of a country embargoed by India, or are a foreign person or entity blocked or denied by the Indian government. Unless otherwise 
                        explicitly stated, all materials found on the Service are solely directed to individuals, companies, or other entities located in India.
                    </p>
                </li>
                <li>
                    <h5>Governing Law and Arbitration</h5>
                    <ul>
                        <li>
                            <h6>Governing Law</h6>
                            <p>
                                You agree that: (i) the Service shall be deemed solely based in New Delhi, India; and (ii) the Service shall be deemed a passive one that does not 
                                give rise to personal jurisdiction over TheTrendNote, either specific or general, in jurisdictions other than New Delhi, India. This Agreement shall 
                                be governed by the internal substantive laws of India, without respect to its conflict of laws principles. The parties acknowledge that this Agreement 
                                evidences a transaction involving interstate commerce. Notwithstanding the preceding sentences with respect to the substantive law, any arbitration 
                                conducted pursuant to the terms of this Agreement shall be governed by the laws of India. You agree to submit to the personal jurisdiction of the 
                                federal and state courts located in New Delhi, India for any actions for which we retain the right to seek injunctive or other equitable relief in 
                                a court of competent jurisdiction to prevent the actual or threatened infringement, misappropriation or violation of a our copyrights, trademarks, 
                                trade secrets, patents, or other intellectual property or proprietary rights, as set forth in the Arbitration provision below.
                            </p>
                        </li>
                        <li>
                            <h6>Arbitration</h6>
                            <p>
                                READ THIS SECTION CAREFULLY BECAUSE IT REQUIRES THE PARTIES TO ARBITRATE THEIR DISPUTES AND LIMITS THE MANNER IN WHICH YOU CAN SEEK RELIEF FROM 
                                COMPANY. In the unlikely event that TheTrendNote has not been able to resolve a dispute it has with you after 60 days, we each agree to resolve 
                                any claim, dispute, or controversy (excluding any TheTrendNote claims for injunctive or other equitable relief) arising out of or in connection 
                                with or relating to these Terms of Service, or the breach or alleged breach thereof (collectively, “Claims”), except as provided herein. 
                                The arbitration will be conducted in New Delhi, India, unless you and TheTrendNote agree otherwise. Each party will be responsible for paying any 
                                government filing, administrative and arbitrator fees in accordance with the government rules. The award rendered by the arbitrator shall include 
                                costs of arbitration, reasonable attorneys’ fees and reasonable costs for expert and other witnesses, and any judgment on the award rendered by the 
                                arbitrator may be entered in any court of competent jurisdiction. Nothing in this Section shall be deemed as preventing TheTrendNote from seeking 
                                injunctive or other equitable relief from the courts as necessary to protect any of TheTrendNote’s proprietary interests. ALL CLAIMS MUST BE BROUGHT 
                                IN THE PARTIES’ INDIVIDUAL CAPACITY, AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS ACTION, COLLECTIVE ACTION, PRIVATE ATTORNEY 
                                GENERAL ACTION OR OTHER REPRESENTATIVE PROCEEDING. THIS WAIVER APPLIES TO CLASS ARBITRATION, AND, UNLESS WE AGREE OTHERWISE, THE ARBITRATOR MAY NOT 
                                CONSOLIDATE MORE THAN ONE PERSON’S CLAIMS. YOU AGREE THAT, BY ENTERING INTO THIS TERMS OF SERVICE, YOU AND COMPANY ARE EACH WAIVING THE RIGHT TO A 
                                TRIAL BY JURY OR TO PARTICIPATE IN A CLASS ACTION, .COLLECTIVE ACTION, PRIVATE ATTORNEY GENERAL ACTION, OR OTHER REPRESENTATIVE PROCEEDING OF ANY 
                                KIND.
                            </p>
                        </li>
                    </ul>
                </li>
                <li>
                    <h5>General</h5>
                    <ul>
                        <li>
                            <h6>Assignment</h6>
                            <p>
                                This Agreement, and any rights and licenses granted hereunder, may not be transferred or assigned by you, but may be assigned by TheTrendNote 
                                without restriction. Any attempted transfer or assignment in violation hereof shall be null and void.
                            </p>
                        </li>
                        <li>
                            <h6>Notification Procedures and Changes to the Agreement</h6>
                            <p>
                                TheTrendNote may provide notifications, whether such notifications are required by law or are for marketing or other business related purposes, 
                                to you via email notice, written or hard copy notice, or through posting of such notice on our website, as determined by TheTrendNote in our 
                                sole discretion. TheTrendNote reserves the right to determine the form and means of providing notifications to our Users, provided that you may 
                                opt out of certain means of notification as described in this Agreement. TheTrendNote is not responsible for any automatic filtering you or your 
                                network provider may apply to email notifications we send to the email address you provide us. TheTrendNote may, in its sole discretion, modify 
                                or update this Agreement from time to time, and so you should review this page periodically. When we change the Agreement in a material manner, 
                                we will update the ‘last modified’ date at the bottom of this page. Your continued use of the Service after any such change constitutes your 
                                acceptance of the new Terms of Service. If you do not agree to any of these terms or any future Terms of Service, do not use or access (or continue 
                                to access) the Service.
                            </p>
                        </li>
                        <li>
                            <h6>Entire Agreement/Severability</h6>
                            <p>
                                This Agreement, together with any amendments and any additional agreements you may enter into with TheTrendNote in connection with the Service, 
                                shall constitute the entire agreement between you and TheTrendNote concerning the Service. If any provision of this Agreement is deemed invalid 
                                by a court of competent jurisdiction, the invalidity of such provision shall not affect the validity of the remaining provisions of this Agreement, 
                                which shall remain in full force and effect.
                            </p>
                        </li>
                        <li>
                            <h6>No Waiver</h6>
                            <p>
                                No waiver of any term of this Agreement shall be deemed a further or continuing waiver of such term or any other term, and TheTrendNote’s 
                                failure to assert any right or provision under this Agreement shall not constitute a waiver of such right or provision.
                            </p>
                        </li>
                        <li>
                            <h6>Contact</h6>
                            <p>
                                Please contact us at admin@thetrendnote.com with any questions regarding this Agreement.
                            </p>
                        </li>
                    </ul>
                </li>
            </ol>
        </div>
    );
};

export default Terms;