import React, { useEffect } from 'react';
import { Container, Row, Col, Form, Button, Spinner, Card } from "react-bootstrap";
import Aos from "aos";
import "aos/dist/aos.css";
const Events = () => {
    useEffect(() => {
        Aos.init({ duration: 2000 });
    }, [])
    return (
        <div data-aos="slide-up" className="spacer2 whiteblacklayer" style={{textAlign:'center',paddingBottom:"0", width:"100%", overflow:"hidden",}}>
            <h1 data-aos="fade-up" className="eventsh1"><u>PLUNGE EVENTS</u></h1>
            <h1 className="eventsh1second">CELEBRATING SOON</h1>
            {/* <Row style={{width:"100%"}}>
                <Col>
                </Col>
                <Col className="events-img-col">
                    <img className="events-img" src="https://trendbucket.s3.ap-south-1.amazonaws.com/flamingo+plunge.png"/>
                </Col>
            </Row> */}
            <img className="events-img" src="https://trendbucket.s3.ap-south-1.amazonaws.com/flamingoplunge.png"/>
        </div>
    );
};

export default Events;