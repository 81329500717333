import React from "react";
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import Layout from './hocs/Layout';
import Home from './components/Home';
import About from './components/About';
import Flavors from './components/Flavors';
import Story from './components/Story';
import Events from './components/Events';
import Privacy from './components/Privacy';
import Age from './components/Age';
import Contact from './components/Contact';
import Terms from './components/Terms';

const App = () => {
    return (
        <Router>
            <Layout>
                <Switch>
                    <Route exact path='/' component={Age}/>
                    <Route exact path='/home' component={Home}/>
                    <Route exact path='/about' component={About}/>
                    <Route exact path='/cocktails' component={Flavors}/>
                    <Route exact path='/story' component={Story}/>
                    <Route exact path='/events' component={Events}/>
                    <Route exact path='/privacy' component={Privacy}/>
                    <Route exact path='/contact' component={Contact}/>
                    <Route exact path='/terms' component={Terms}/>
                </Switch>
            </Layout>
        </Router>
    );
};

export default App;