import React from 'react';

const Contact = () => {
    return (
        <div style={{ width:"100%", overflow:"hidden",}}>
            <h1 className="contact-h1-1">DROP US A MESSAGE</h1>
            <hr/>
            <h1 className="contact-h1-2">THIRSTY FOR SOME TASTY COCKTAILS? <br/> <br/><span style={{fontFamily:"'Rowdies', cursive"}}>Contact us to ask questions, express concerns, or better yet, share the love.</span></h1>
            <hr/>
            <p className="contact-p">Call us at <br/><span style={{color:"darkorange"}}>+91-9560696999</span> <br/><br/>Drop a mail at <br/><span style={{color:"darkorange"}}>feedback@plungecocktails.com</span></p>
        </div>
    );
};

export default Contact;