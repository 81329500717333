import React from 'react';
import { Link } from 'react-router-dom'
import Wave from 'react-wavify';
import { SocialIcon } from 'react-social-icons';

const Footer = () => {
    return (
        <>
        <Wave
            fill="url(#gradient)"
            paused={false}
            options={{
                height: 30,
                amplitude: 20,
                speed: 0.30,
                points: 10
            }}
            style={{backgroundColor:"black"}}
        >
            <defs>
                <linearGradient id="gradient" gradientTransform="rotate(90)">
                <stop offset="100%"  stopColor="white" />
                <stop offset="0%" stopColor="black" />
                </linearGradient>
            </defs>
        </Wave>
        <div className='footer-wrapper'>
            <div className="footer" style={{color:"black"}}>
            <Link to="/"><img src="https://trendbucket.s3.ap-south-1.amazonaws.com/flamingoplunge.png" style={{width:"8rem", marginLeft:"1rem"}}/></Link>

                <div>
                    <Link style={{textDecoration:"none", color:"black"}} to="/about" onClick={() => window.scrollTo({top: 0,behavior: 'smooth'})}>About Plunge&nbsp;</Link> | &nbsp;
                    <Link style={{textDecoration:"none", color:"black"}} to="/story" onClick={() => window.scrollTo({top: 0,behavior: 'smooth'})}>Our Story&nbsp;</Link> | &nbsp;
                    <Link style={{textDecoration:"none", color:"black"}} to="/cocktails" onClick={() => window.scrollTo({top: 0,behavior: 'smooth'})}>  Cocktail Menu&nbsp;</Link> | &nbsp;
                    <Link style={{textDecoration:"none", color:"black"}} to="/events" onClick={() => window.scrollTo({top: 0,behavior: 'smooth'})}>Plunge Events&nbsp;</Link>
                </div>
                <Link style={{textDecoration:"none", color:"black"}} to="/terms" onClick={() => window.scrollTo({top: 0,behavior: 'smooth'})}>Terms&nbsp;</Link> | &nbsp;
                <Link style={{textDecoration:"none", color:"black"}} to="/privacy" onClick={() => window.scrollTo({top: 0,behavior: 'smooth'})}>  Privacy&nbsp;</Link> | &nbsp;
                <Link style={{textDecoration:"none", color:"black"}} to="/contact" onClick={() => window.scrollTo({top: 0,behavior: 'smooth'})}> Contact&nbsp;</Link>  &nbsp;
                {/* <Link style={{textDecoration:"none", color:"white"}} to="/faq"> FAQs</Link> */}
                <div>
                    <SocialIcon className="footer-social" url="https://twitter.com/plungecocktails" target="_blank"/>
                    <SocialIcon className="footer-social" url="https://www.facebook.com/plungecocktails" target="_blank"/> 
                    <SocialIcon className="footer-social" url="https://www.linkedin.com/company/plungecocktails/" target="_blank"/>
                    <SocialIcon className="footer-social" url="https://www.instagram.com/plungecocktails/" target="_blank"/>
                </div>
                <br/>
                <div>
                    <p style={{color:"black", margin:"0"}}>16/62, West Punjabi Bagh, New Delhi - 110026, India</p>
                    <p style={{color:"black"}}><SocialIcon className="footer-social" network="whatsapp" target="_blank"/>+91 9560696999</p>
                    <p>Please drink responsibly.</p>
                </div>
            </div>   
        </div>
        </>
    );
};

export default Footer;