import React, {useEffect} from 'react';
import { Container, Row, Col, Form, Button, Spinner, Card } from "react-bootstrap";
import Aos from "aos";
import "aos/dist/aos.css";

const Story = () => {
    useEffect(() => {
        Aos.init({ duration: 2000 });
    }, [])
    return (
        <div style={{width:"100%", overflow:"hidden"}}>
            <div data-aos="slide-up" className="spacer2 whiteblacklayer">
                <h1 data-aos="fade-up" className="story-start">OUR STORY</h1>
                <h1 data-aos="fade-up" className="story-title">BACK TO THE FUTURE OF COCKTAILS</h1>
                <p data-aos="fade-up" className="story-desc">It all started with identifying a very simple problem that we faced during and post the pandemic. With the increased culture of house parties throughout the world, a lot of us felt the need to move beyond just Whiskey and Beer - to the good old cocktails that we get in bars and restaurants. <br/><br/>But one problem that we all face is the efforts required to curate the raw materials required to create amazing cocktails, let alone the cost. Thus, in 2022, we took it upon ourselves to make this whole process easy (you bet!) for ourselves and the world. <br/><br/>We created the very first cocktail brand in India to offer the most popular and complicated cocktails in the world at only 5 calories per can - <span style={{color:"#FF033E"}}>Berry Bomb, Tropical Pinacolada, Watermelon Mint Cosmopolitan, Orange Passion Screwdriver, and Guava Mule.</span> <span className="color-change"> And that too without any sugar!</span></p>
                <Row>
                    <Col lg="7" className='story-col-img'>
                        <img data-aos="fade-up" className="story-img" src="https://trendbucket.s3.ap-south-1.amazonaws.com/beachplunge.jpg"/>
                    </Col>
                    <Col lg="5" className='story-col-text'>
                        <h1 data-aos="slide-left" className="story-title-right">Born in <span style={{color:"darkorange"}}>India.</span><br/>For the <span style={{color:"green"}}>World.</span></h1>
                        <p data-aos="slide-left" className="story-desc-right">Plunge cocktails are 100% Made in India. Delicious, extremely low-calorie ready-to-drink cocktails with unique flavors that can just sit in your fridge. Enjoy Plunge cocktails anywhere, any time!<br/><br/> We're ready to serve the world.</p>
                    </Col>
                </Row>
            </div>
            <div data-aos="slide-up" className="spacer2 blackwhitelayer">
                <Row>
                <Col lg="5" className='story-col-text'>
                        <h1 data-aos="slide-right" className="story-title-left">WE <span style={{color:"darkred"}}>PLUNGE'd</span> AND HOW!</h1>
                        <p data-aos="slide-right" className="story-desc-left">We researched for many months, spoke to several industry experts and travelled across multiple states in India to look for the best partners to create an unforgettable product. <br/><br/>We partnered with an experienced food chemist to create a scalable commercial formulation. In our passion towards the product, we created the complete package design ourselves using years of experience in technology and software. <br/><br/>Our search for a suitable production facility landed us right into the party capital of India - Goa! Finally, after months of hard work, we were able to assemble a fantastic team to start operations in 2023.</p>
                    </Col>
                    <Col lg="7" className='story-col-img'>
                        <img data-aos="fade-up" className="story-img" src="https://trendbucket.s3.ap-south-1.amazonaws.com/distillery.png"/>
                    </Col>
                </Row>
            </div>
            <div data-aos="slide-up" className="spacer2 whiteblacklayer">
            <Row>
                <Col lg="7" className='story-col-img'>
                    <img data-aos="fade-up" className="story-last-img" src="https://trendbucket.s3.ap-south-1.amazonaws.com/barcan.png"/>
                </Col>
                <Col lg="5" className='story-col-text'>
                    <h1 data-aos="slide-left" className="story-last-title"><span style={{color:"red"}}>CALM</span> amongst the <span style={{color:"red"}}>CHAOS</span></h1>
                    <p className="story-last-p">Plunge aims to become your partner in all your memorable moments. We're on our way to successfully reach the Indian youth through multiple cities across India. <br/><br/> We promise to come up with more amazing variants of the most popular cocktails in the world guaranteeing the best quality at affordable prices for all to enjoy anywhere and everywhere - whether at home, in your car, at a beach or at nightclubs.<br/><br/>Experience the calm amongst the chaos with Plunge!</p>
                </Col>
            </Row>
            </div>
        </div>
    );
};

export default Story;