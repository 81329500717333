import React from 'react';
import Navigation from '../components/Navigation';
import Footer from '../components/Footer';
const Layout = (props) => {
    return (
        <div>
            <Navigation/>
            {props.children}
            <Footer />
        </div>
    );
};

export default Layout;


