import React, {useEffect} from 'react';
import { Container, Row, Col, Form, Button, Spinner } from "react-bootstrap";
import {BsChevronDoubleDown} from 'react-icons/bs';
import Aos from "aos";
import "aos/dist/aos.css";

const Flavors = () => {
    useEffect(() => {
        Aos.init({ duration: 2000 });
    }, [])
    return (
        <div style={{width:"100%", overflow:"hidden",}}>
            <h1 data-aos="slide-up" className="flavors-h1"><u>COCKTAIL MENU</u></h1>
            <h1 data-aos="fade-up" className="flavors-h1-2">LIFE IS FULL OF FLAVOR</h1>
            {/* <h1 style={{textAlign:"center", fontFamily: "'Limelight', cursive", paddingLeft:"20rem", paddingRight:"20rem"}}><span style={{color:"darkorange"}}>PLUNGE</span> INTO THE WORLD OF <span style={{color:"darkorange"}}>PREMIUM TASTE</span>.<br/> THERE'S SOMETHING FOR EVERYONE.</h1> */}
            <h1 data-aos="slide-up" className="flavors-h1-3"><span style={{color:"darkorange"}}>Plunge</span> into the world of <span style={{color:"darkorange"}}>premium taste</span>.<br/> There's something for everyone, <br/> with <span style={{color:"darkorange"}}>only 5 Calories</span> in every can.</h1>
            {/* <div style={{backgroundColor:"black", textAlign:"center", paddingTop:"2rem", paddingBottom:"2rem"}}>
                <h1 style={{marginTop:"0rem", fontFamily:"'Rubik Dirt', cursive", color:"white", fontSize:"3rem"}}>8% ABV. ZERO SUGAR.</h1>
                <h1 style={{fontFamily:"'Rubik Dirt', cursive", color:"white"}}>5 REFRESHING COCKTAILS</h1>
            </div> */}
            <div data-aos="slide-up" className="spacer2 layer1 flavors-top-div">
                <h1 data-aos="slide-down" className="fl-h1-1">8% ABV. ZERO SUGAR.</h1>
                <h1 data-aos="fade-down" className="fl-h1-2">5 REFRESHING COCKTAILS</h1>
                <BsChevronDoubleDown className="down-arrow"/>
                <Row className="fl-top-row" xs={1} sm={1} lg={2}>
                    <Col data-aos="slide-right" className="fl-col">
                        <h1 data-aos="slide-right" className="first-flavor"><u>ORANGE PASSION SCREWDRIVER</u></h1>
                        <h1 data-aos="slide-right" className="first-desc">A TASTY MIX OF PASSIONFRUIT AND ORANGE SPIKED WITH THE PERFECT AMOUNT OF ALCOHOL.</h1>
                    </Col>
                    <Col data-aos="slide-left">
                        <img className="first-fl-img" src="https://trendbucket.s3.ap-south-1.amazonaws.com/orangequote.png"/>
                    </Col>
                </Row>
            </div>
            <div data-aos="slide-up" className="spacer2 layer2" style={{marginTop:"5rem"}}>
                <Row className="fl-top-row" xs={1} sm={1} lg={2}>
                    <Col data-aos="slide-right">
                        <img className="flavor-image-left-pina" src="https://trendbucket.s3.ap-south-1.amazonaws.com/pinaquote.png"/>
                    </Col>
                    <Col data-aos="slide-left" className="fl-col">
                        <h1 data-aos="slide-left" className="flavor-title-left"><u>TROPICAL PINACOLADA</u></h1>
                        <h1 data-aos="slide-left" className="flavor-desc-left">THE CLASSIC COMBINATION OF PINEAPPLE AND COCONUT TO GIVE YOU THE TROPICAL VIBE WHEREVER YOU ARE.</h1>
                    </Col>
                </Row>
            </div>
            <div data-aos="slide-up" className="spacer3 layer3">
                <Row className="fl-top-row" xs={1} sm={1} lg={2}>
                    <Col data-aos="slide-right" className="fl-col">
                        <h1 data-aos="slide-right" className="flavor-title-right"><u>BERRY BOMB</u></h1>
                        <h1 data-aos="slide-right" className="flavor-desc-right">YOU'LL LOVE THIS IF YOU LOVE YOUR BERRIES LIKE WE DO. IT'S LIKE A BLAST OF BERRIES IN THE MOUTH WITH THE SWEET BUZZ OF COURSE!</h1>
                    </Col>
                    <Col data-aos="slide-left">
                        <img className="flavor-image-right" src="https://trendbucket.s3.ap-south-1.amazonaws.com/berryquote.png"/>
                    </Col>
                </Row>
            </div>
            <div data-aos="slide-up" className="spacer2 layer4">
                <Row className="fl-top-row" xs={1} sm={1} lg={2}>
                    <Col data-aos="slide-right">
                        <img className="flavor-image-left" src="https://trendbucket.s3.ap-south-1.amazonaws.com/watermelonquote.png"/>
                    </Col>
                    <Col data-aos="slide-left" className="fl-col">
                        <h1 data-aos="slide-left" className="flavor-title-left"><u>WATERMELON MINT <br/>COSMO</u></h1>
                        <h1 data-aos="slide-left" className="flavor-desc-left">WATERMELON COSMO WITH A HINT OF MINT, GIVING THE DRINK AN EXTRA REFRESHING FLAVOUR. YOU HAVE TO TRY IT TO BELIEVE HOW GOOD IT IS!</h1>
                    </Col>
                </Row>
            </div>
            <div data-aos="slide-up" className="spacer2 layer5">
                <Row className="fl-top-row" xs={1} sm={1} lg={2}>
                    <Col data-aos="slide-right" className="fl-col">
                        <h1 data-aos="slide-right" className="flavor-title-right"><u>GUAVA MULE</u></h1>
                        <h1 data-aos="slide-right" className="flavor-desc-right">GUAVA MIXED WITH LIME AND GINGER TO ADD THAT HINT OF SPICE TO YOUR DRINK.</h1>
                    </Col>
                    <Col data-aos="slide-left">
                        <img className="flavor-image-right" src="https://trendbucket.s3.ap-south-1.amazonaws.com/guavaquote.png"/>
                    </Col>
                </Row>
            </div>
        </div>
    );
};

export default Flavors;