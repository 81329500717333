import React, { useEffect, useState } from "react";
import { Container, Row, Col, Form, Button, Spinner, Car, Modal } from "react-bootstrap";
import { Link } from 'react-router-dom'
import AnimatedText from 'react-animated-text-content';
import Aos from "aos";
import "aos/dist/aos.css";

const Age = () => {
    const [fullscreen, setFullscreen] = useState(true);
    const [show, setShow] = useState(true);
    const [msg, setMsg] = useState(false);

    const verified = () => {
        return window.location = '/home';
    }

    return (
        <>
            <Modal show={show} fullscreen={fullscreen} onHide={() => setShow(false)}>
                <Modal.Body style={{textAlign:"center",}}>
                    <img src="https://trendbucket.s3.ap-south-1.amazonaws.com/plunge-logo.png" className="plunge-logo" style={{marginBottom:"5em"}}/>
                    <h1 style={{textAlign:"center", fontFamily:"'Rubik Dirt', cursive", color:"black"}}>Are you over 18?</h1>
                    <Button size="lg" className="age-button" style={{borderRadius:"0"}} onClick={() => verified()}>Yes</Button>
                    <Button size="lg" className="age-button" style={{borderRadius:"0"}} onClick={() => setMsg(true)}>No</Button>
                    {msg!==false && <p style={{marginTop:"2em"}} ><b>*PLEASE COME BACK AND VISIT US WHEN YOU'RE 18.</b></p>}
                </Modal.Body>
            </Modal>
        </>
    );
};

export default Age;