import React from 'react';

const Privacy = () => {
    return (
        <div style={{width:"60%", margin:"auto"}}>
            <h1 style={{fontFamily:"'Rubik Dirt', cursive", fontSize:"8rem", marginTop:"5rem", color:"black", textAlign:"center"}}>PRIVACY POLICY</h1>
            <div>
                <h4>Last Revised: Feb 02, 2023</h4>
                <p>
                    Topdog Beverages Pvt. Ltd. (“Topdog”, "Plunge", “we”, "our" or “us”) is the owner and operator of the Site. Topdog respects your 
                    privacy, and we created this privacy policy (the “Policy”) to explain how we collect, use and disclose your information when you access the Site. The Policy is only applicable to our Site and our Services and not to any other website, service or online platform. Capitalized terms that 
                    are not defined in this Policy have the meaning given them in our Terms of Service. BY ACCESSING THE SITE, UTILIZING ANY OF THE SERVICES AND/OR REGISTERING 
                    WITH US, YOU SIGNIFY THAT YOU HAVE READ, UNDERSTOOD AND AGREE TO BE BOUND BY THIS POLICY, INCLUDING OUR TERMS OF SERVICE, WHICH ARE INCORPORATED HEREIN BY 
                    REFERENCE.
                </p>
                <h4>Types of Information Collected</h4>
                <ul>
                    <li>
                        <h5>Personal Information</h5>
                        <p>
                            Topdog may ask you for some or all of the following types of information which may be identifiable to you when you register for our services, 
                            access various content or features, or directly contact the site: (i) contact information, such as your email address and name; (ii) your age; and 
                            (iii) information for the purpose of authenticating yourself or your account if we have reason to believe, in our sole discretion, that you may be 
                            violating site policies or for any other reason we deem necessary (collectively “Personal Information”). You are responsible for ensuring the accuracy 
                            of all Personal Information you submit to Topdog.
                        </p>
                        <p>
                            We collect and store all Personal Information you submit to us voluntarily through registering with us or uploading or posting User Content through 
                            the Site or Services. If you are signed into your Tumblr, Twitter or Facebook account and you “like” or “comment” on our Content (such as the 
                            articles found on our blog) or other User Content, your Tumblr, Twitter or Facebook username will be displayed and so become available to the public. 
                            You agree that your username, likes, feedback, and any associated comments may become public information; we therefore urge you to exercise caution 
                            when deciding to disclose any Personal Information on the Site or through the Services.
                        </p>
                    </li>
                    <li>
                        <h5>Non-Personal Information</h5>
                        <p>
                            We may utilize cookies and other technologies, such as, web beacons (also known as “clear gifs” and “pixel tags”) to recognize you, customize your 
                            experience, and serve advertisements. Cookies are very small files placed on your computer, and they allow us to count the number of visitors to 
                            Plunge and distinguish repeat visitors from new visitors. They also allow us to save User preferences and track User trends. We do not link 
                            cookies or web beacons to any Personal Information. We may also enable advertisers and ad servers to promote third party products and/or services by 
                            placing advertisements on Plunge. These advertisers and ad servers may use cookies and/or web beacons to monitor information related to served 
                            advertisements. These cookies allow the ad server to recognize your computer each time they send you an online advertisement to compile information 
                            about you or others who use your computer. This information allows ad networks to, among other things, deliver targeted advertisements that they 
                            believe will be of most interest to you. This Policy covers the use of cookies by Topdog and does not cover the use of cookies by any 
                            advertisers.
                        </p>
                    </li>
                    <li>
                        <h5>Information Collected Automatically</h5>
                        <p>
                            We automatically receive and collect information such as your IP address, the URLs of websites you arrive at Plunge website from or leave Plunge website 
                            to go to, your type of browser and language, access times, your email client, the content of any undeleted cookies that your browser previously 
                            accepted from us, your operating system, your mobile provider, your mobile device, and your ISP. We may use such information to better understand 
                            how Plunge visitors use the Site, to analyze trends, administer the website, prevent fraud, and gather broad demographic information.
                        </p>
                    </li>
                <li>
                    <h5>Our Use of Your Information</h5>
                    <p>
                        Our primary purpose in collecting information, both Personal Information and other, is to provide you with a safe, smooth, efficient, and customized 
                        experience. You agree that we may use your information to:
                    </p>
                    <p>
                        Provide the services and customer support you request. Troubleshoot problems and prevent potentially prohibited or illegal activities. Customize, 
                        measure and improve our Services, content, and advertising. Tell you about our Services, targeted marketing, service updates, and promotional offers 
                        based on your communication preferences. Monitor and enforce our Terms of Service. Communicate with Users about products and/or services they may 
                        be interested in. Verify your eligibility to use the Site.
                    </p>
                </li>
                <li>
                    <h5>Our Disclosure of Your Information</h5>
                    <p>
                        Topdog is committed to maintaining your trust and we want you to understand when and with whom we may share information collected about you. 
                        We may disclose Personal Information to respond to legal requirements, enforce our policies, respond to claims that a listing or other content violates 
                        the rights of others, or protect anyone’s rights, property, or safety. Such information will be disclosed in accordance with applicable laws and 
                        regulations.
                    </p>
                    <p>We may also share your Personal Information with:</p>
                    <p>
                        Service providers under contract who help with our business operations (we employ other companies and people to perform tasks on our behalf and need 
                        to share your information with them to provide products and services to you). Certain of Topdog’s program participants under confidentiality 
                        agreement, as we in our sole discretion believe necessary or appropriate in connection with an investigation of fraud, intellectual property 
                        infringement, piracy, or other unlawful activity. Other business entities, should we plan to merge with or be acquired by that business entity.
                    </p>
                </li>
                <li>
                    <h5>Marketing</h5>
                    <p>
                        We may combine your information with information we collect from other companies and use it to improve and personalize our services, content and 
                        advertising. If you do not wish to receive marketing communications from us or participate in our ad-customization programs, simply indicate your 
                        preference by contacting us at siddhant@plungecocktails.com
                    </p>
                </li>
                <li>
                    <h5>No Spam or Spyware</h5>
                    <p>
                        We do not tolerate spam. Spamming is explicitly prohibited by our Terms of Service. If you would like to report an incident of spamming or other 
                        unauthorized communications from other Users, please contact us at siddhant@plungecocktails.com. We maintain the right to investigate such incidents and 
                        take suitable action.
                    </p>
                </li>
                <li>
                    <h5>Account Protection</h5>
                    <p>
                        The Personal Information we collect is stored on a secure, password-protected server and only authorized personnel have access to your information. 
                        We use industry-standard encryption technologies with respect to the receipt and transfer of Personal Information you submit. Nevertheless, despite 
                        our best efforts, no transmission over the Internet and no data storage method can be guaranteed to be safe 100% of the time. Topdog cannot 
                        ensure or warrant the security of any information you transmit to Topdog or guarantee that your end user data stored on the Services may not 
                        be accessed, disclosed, altered, or destroyed by breach of any of our industry standard physical, technical, or managerial safeguards. If you enter 
                        sensitive information we will encrypt such information using secure socket layer technology (SSL). The Site is hosted on Heroku and the servers are 
                        managed by Heroku. Accordingly, if you are located outside of India, the Personal Information you provide to us will be transferred to India and might 
                        also go to the country where Heroku hosts its servers. By submitting your Personal Information, you consent to its transfer and storage in India and 
                        its use in accordance with the purposes for which it was originally collected.
                    </p>
                </li>
                <li>
                    <h5>Email Communications</h5>
                    <p>
                        We may use your email to deliver Topdog newsletter, as well as for other promotional (e.g., new product offerings, special offers by us) 
                        purposes. Email messages we send you may contain code that enables our database to track your usage of the emails we send you, such usage includes 
                        whether the email was opened and what links (if any) were clicked. If you send an email to us, we will collect your email address and the full 
                        content of your email, including attached files, and other information you provide. Additionally, you may be directed to third-party sites related 
                        to the topics discussed within the email message.
                    </p>
                    <p>
                        You may indicate your preference to opt out of receiving promotional communications by following the unsubscribe instructions provided in the 
                        promotional email you receive or by contacting us directly at siddhant@plungecocktails.com. You acknowledge that it may take up to 10 days for us to 
                        process an opt-out request. Please note, however, that you cannot opt out of receiving transactional emails related to your account with us.
                    </p>
                </li>
                <li>
                    <h5>Third Parties</h5>
                    <p>
                        Except as otherwise expressly included in this Policy, this document addresses only the use and disclosure of information we collect from you. 
                        If you disclose your information to others Users on our Site or on other sites throughout the internet, different rules may apply to their use or 
                        disclosure of the information you disclose to them. We do not control the privacy policies of third parties, and you are subject to the privacy policies 
                        of those third parties where applicable. We are not responsible for any information we do not collect. We encourage you to ask questions before you 
                        disclose your Personal Information to others.
                    </p>
                </li>
                <li>
                    <h5>Children</h5>
                    <p>
                        Protecting the privacy of young children is especially important to us. For that reason, Topdog does not knowingly collect or solicit Personal 
                        Information from anyone under the age of 18.
                    </p>
                </li>
                <li>
                    <h5>Changes to this Policy</h5>
                    <p>
                        We reserve the right, at any time, to add to, change, update, or modify this Policy. Any such changes to this Policy will be posted here on our 
                        Site so we urge to please visit this page frequently. In all cases, use of information we collect is subject to the Policy in effect at the time such 
                        information is collected.
                    </p>
                </li>
            </ul>
            </div>

        </div>
    );
};

export default Privacy;